<template>
  <base-popup v-model="dialog" min-width="800" max-width="1200" headerHidden>
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" icon title="Список сотрудников для ознакомления">
        <m-icon icon="tool-people" s16></m-icon>
      </v-btn>
    </template>
    <base-list-view
      :dataSource="dataSource"
      :headers="headers"
      :loading="loading"
      @onRefresh="init"
      :showDelete="false"
      :notShowActions="['add']"
      title="Список сотрудников для ознакомления с документом"
      :icon="UtilGetIcon('view-Employees')"
      headerHeight="64px"
      notShowSelect
      hide-default-footer
      :sort-by="['Employee']"
    >
      <v-btn slot="action" @click="showReport" icon title="Распечать">
        <m-icon icon="tool-print" size="18"></m-icon>
      </v-btn>
      <v-btn
        slot="action"
        @click="dialog = false"
        dark
        color="blue"
        class="ml-4"
      >
        Закрыть
      </v-btn>
      <template #item.Status="{ item }">
        <span :style="item.Color">{{ status[item.Status] }}</span>
      </template>
    </base-list-view>
  </base-popup>
</template>

<script>
import LocalDocumentService from "../../../services/LocalDocumentService";
import BaseListView from "../../../layouts/BaseListView.vue";
import BasePopup from "@/layouts/BasePopup.vue";
import { uniqBy } from "lodash";

const status = ["На ознакомлении", "Ознакомлен(-а)"];
export default {
  name: "view-DocumentAcquaintance",
  components: { BaseListView, BasePopup },
  props: {
    documentId: Number,
  },
  data: function () {
    return {
      dialog: false,
      loading: true,
      dataLoaded: false,

      headers: [
        {
          text: "Ф.И.О.",
          value: "Employee",
          dataType: "object",
          displayText: (e) => this.$options.filters.PersonShortName(e),
        },
        {
          text: "Должность",
          value: "AllPositionName",
        },
        {
          text: "Подразделение",
          value: "AllPlaceName",
        },
        {
          text: "Статус",
          value: "Status",
          dataType: "enum",
          options: status,
          align: "center",
        },
        {
          text: "Дата",
          value: "Date",
          dataType: "Date",
          width: 170,
          align: "center",
        },
      ],
      dataSource: [],
      status,
    };
  },
  watch: {
    dialog(value) {
      if (value && !this.dataLoaded) {
        this.dataLoaded = true;
      }
      if (value) {
        this.init();
      }
    },
  },
  methods: {
    async init() {
      try {
        this.loading = true;
        const res = await LocalDocumentService.getAcuaintance(this.documentId);
        this.dataSource = res.data;
        this.dataSource.forEach((item) => {
          item.AllPositionName = null;
          item.AllPlaceName = null;

          const wp = item.Employee.WorkPlaces?.filter((e) => e !== null);

          item.AllPositionName = uniqBy(
            wp.filter((e) => e.Position),
            "Position.Id"
          )
            .map((e) => e?.Position?.Name)
            .sort()
            .join(", ");

          item.AllPlaceName = uniqBy(
            wp.filter((e) => e.Place),
            "Place.Id"
          )
            .map((e) => e?.Place?.Name)
            .sort()
            .join(", ");

          item.Status = item.Date ? 1 : 0;
          item.Color = item.Date === null ? {} : { color: "green" };
        });
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
    showReport() {
      this.$router.push({
        name: "ReportPage",
        params: {
          report: "DocumentAcquaintanceReport",
          objectId: this.documentId,
        },
        query: {
          objectName: "pDocumentId",
        },
      });
    },
    refresh() {
      this.dataLoaded = false;
    },
  },
};
</script>
