<template>
  <div v-if="fields" :class="classWrapper" class="dynamic-inputs__wprapper">
    <template
      v-for="field in fields.filter((e) => e.Name !== 'Content' && !e.Hidden)"
    >
      <div
        :style="
          fixedWidth
            ? 'display: flex; width: ' +
              field.Width * 100 +
              '%;     height: 100%;'
            : ''
        "
        :class="{ 'px-3': fixedWidth }"
        class="dynamic-inputs__item"
        :key="field.Name"
      >
        <component
          v-if="field.Type === 1 || field.Type === 2"
          v-model="dataSource[field.Name]"
          @input="(value) => handleInput(field.Name, value)"
          :is="getComp(field)"
          :label="field.DisplayName || field.Name"
          :readonly="
            $attrs['readonly'] ||
            !(field.Enabled === undefined || field.Enabled)
          "
          v-bind="getBind(field)"
          v-up-first-symbol
        >
        </component>
        <component
          v-else
          v-model="dataSource[field.Name]"
          @input="(value) => handleInput(field.Name, value)"
          :is="getComp(field)"
          :label="field.DisplayName || field.Name"
          v-bind="getBind(field)"
          :readonly="
            $attrs['readonly'] ||
            !(field.Enabled === undefined || field.Enabled)
          "
          v-up-first-symbol
        >
        </component>
      </div>
    </template>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { diff } from "deep-diff";
import mSelect from "@/components/base/inputs/mSelect.vue";
import mTreeSelect from "@/components/base/inputs/mTreeSelect.vue";
import mAutocomplete from "@/components/base/inputs/mAutocomplete.vue";
import { VTextField, VCheckbox, VTextarea } from "vuetify/lib";
import BaseDatePicker from "@/components/base/BaseDatePicker.vue";
import DocumentSelectEmlpoyees from "@/views/document/components/DocumentSelectEmlpoyees.vue";
import LocalDocumentService from "@/services/LocalDocumentService";
import InputLoadFile from "@/components/loadFiles/InputLoadFile.vue";
import { orderBy } from "lodash";

export default {
  components: {
    mSelect,
    mTreeSelect,
    mAutocomplete,
    VTextField,
    VCheckbox,
    VTextarea,
    BaseDatePicker,
    DocumentSelectEmlpoyees,
    InputLoadFile,
  },
  props: {
    fields: {
      type: Array,
      default: null,
    },
    type: {
      type: Number,
      default: 0, // 0 - документы, 1 - НС
      validator: function (e) {
        return [0, 1].includes(e);
      },
    },
    classWrapper: { type: String, default: null },
    fixedWidth: { type: Boolean, default: false },
  },
  data() {
    return {
      dictionary: {
        LocalDocuments: [],
      },
      dataSource: {},
      DocumentTemplateParameterType: {
        0: {
          input: "m-select",
          bind: {},
        },
        1: {
          input: "v-text-field",
          bind: {
            type: "number",
          },
        },
        2: {
          input: "v-text-field",
          bind: {
            type: "number",
          },
        },
        3: {
          input: "v-checkbox",
        },
        4: {
          input: "v-text-field",
        },
        5: {
          input: "base-date-picker",
        },
        6: {
          input: "v-text-field",
        },
        7: {
          input: "v-text-field",
        },
        11: {
          input: "m-select",
          bind: {
            dict: "Employees",
            returnObject: true,
            filter: "EmployeeActive",
          },
        },
        12: {
          input: "m-tree-select",
          bind: {
            dict: "Partition",
            returnObject: true,
            allowSelectParents: true,
            itemText: "Name",
          },
        },
        13: {
          input: "m-autocomplete",
          bind: {
            dict: "Organizations",
            returnObject: true,
            itemText: "Name",
          },
        },
      },
    };
  },
  computed: {
    sortedEmployees() {
      return orderBy(this.$store.state.employees, "LastName");
    },
  },
  watch: {
    fields: {
      deep: true,
      immediate: true,
      handler: function () {
        this.init();
      },
    },
  },
  created() {
    this.LOAD_ORGGANIZATION_DOCUMENT_TYPES();
    this.LOAD_AUDIT_INDICATOR_CATEGORIES();
  },
  methods: {
    ...mapActions("loaderDataSources", ["LOAD_ORGGANIZATION_DOCUMENT_TYPES"]),
    ...mapActions(["LOAD_AUDIT_INDICATOR_CATEGORIES"]),
    async init() {
      for (const field of this.fields) {
        const n = field.Name;
        if (!this.dataSource[n]) {
          this.$set(this.dataSource, n, field.Value);
          this.$watch("dataSource." + n, (val) => {
            this.$emit("update", { name: n, value: val });
          });
        } else if (diff(this.dataSource[n], field.Value)) {
          this.$set(this.dataSource, n, field.Value);
        }
        // Подгрузка словарей
        if (
          (n === "Utverzhdayushchiy_dokument" || n === "Iskhodnyy_dokument") &&
          !this.dictionary.LocalDocuments.length
        ) {
          this.dictionary.LocalDocuments = (
            await LocalDocumentService.get()
          ).data;
        }
      }
    },
    handleInput(fieldName, value) {
      if (value === "") {
        this.$set(this.dataSource, fieldName, null);
      } else {
        this.$set(this.dataSource, fieldName, value);
      }
    },
    getComp(field) {
      let input = null;
      if (field.IsBase) {
        switch (field.Name) {
          case "Podpisyvaet_utverzhdaet":
            input = "m-autocomplete";
            break;
          case "Type":
            input = "m-select";
            break;
          case "Data":
            input = "base-date-picker";
            break;
          case "Nomer":
            input = "VTextField";
            break;
          case "Naimenovanie":
            input = "VTextField";
            break;
          case "Data_otmeny":
            input = "base-date-picker";
            break;
          case "Nachalo_perioda_deystviya":
            input = "base-date-picker";
            break;
          case "Okonchanie_perioda_deystviya":
            input = "base-date-picker";
            break;
          case "Utverzhdayushchiy_dokument":
            input = "m-select";
            break;
          case "Avtory":
            input = "m-autocomplete";
            break;
          case "Soglasuyut":
            input = "m-autocomplete";
            break;
          case "Oznakomit":
            input = "document-select-emlpoyees";
            break;
          case "Iskhodnyy_dokument":
            input = "m-select";
            break;
          case "Versiya":
            input = "VTextField";
            break;
          case "Files":
            input = "input-load-file";
            break;
          case "Primechanie":
            input = "VTextarea";
            break;
          case "Razdel_deyatelnosti":
            input = "m-autocomplete";
            break;
        }
        if (input) return input;
      } else if (this.type === 1) {
        switch (field.Name) {
          case "CameFrom":
            input = "m-select";
            break;
          case "BedsoreStage":
            input = "m-select";
            break;
          case "AttachedOrganizationEnum":
            input = "m-select";
            break;
        }
        if (input) return input;
      }

      // Определение селекта по типу
      input = this.DocumentTemplateParameterType[field.Type].input;
      return input;
    },
    getBind(field) {
      let bind = null;
      if (field.IsBase) {
        switch (field.Name) {
          case "Podpisyvaet_utverzhdaet":
            bind = {
              items: this.sortedEmployees,
              dict: "Employees",
              returnObject: true,
              clearable: field.Enabled,
              filter: "EmployeeActive",
              textCustomAdd: "Создать нового сотрудника",
            };
            break;
          case "Type":
            bind = {
              items:
                this.$store.state.loaderDataSources.organizationDocumentTypes,
              itemText: "Name",
              itemValue: "Id",
              returnObject: true,
              clearable: field.Enabled,
            };
            break;
          case "Avtory":
            bind = {
              items: this.sortedEmployees,
              dict: "Employees",
              multiple: true,
              returnObject: true,
              clearable: field.Enabled,
              filter: "EmployeeActive",
              textCustomAdd: "Создать нового сотрудника",
            };
            break;
          case "Soglasuyut":
            bind = {
              items: this.sortedEmployees,
              dict: "Employees",
              multiple: true,
              returnObject: true,
              clearable: field.Enabled,
              chipColor: (item) => {
                const signed = this.dataSource[field.Name].find(
                  (e) => e.Id === item.Id
                )?.Signed;
                return signed === undefined || signed === null
                  ? "#E8ECFF"
                  : signed === false
                  ? "rgba(168, 12, 93, 0.3)"
                  : "rgba(12, 168, 93, 0.3";
              },
              filter: "EmployeeActive",
              textCustomAdd: "Создать нового сотрудника",
            };
            break;
          case "Oznakomit":
            bind = {
              items: this.$store.state.employees,
              dict: "Employees",
              multiple: true,
              returnObject: true,
              clearable: field.Enabled,
              onlyEmployee: false,
              chipColor: (item) => {
                const date = this.dataSource[field.Name].find(
                  (e) => e.Id === item.Id
                )?.Date;
                return date === undefined || date === null
                  ? "#304FFE"
                  : "rgb(12, 168, 93";
              },
              filter: "EmployeeActive",
            };
            break;
          case "Utverzhdayushchiy_dokument":
            bind = {
              items: this.dictionary.LocalDocuments,
              itemText: (item) =>
                "Документ №" +
                (item?.Num ? item?.Num : "") +
                " - " +
                (item?.Name ? item?.Name : ""),
              returnObject: true,
            };
            break;
          case "Iskhodnyy_dokument":
            bind = {
              items: this.dictionary.LocalDocuments,
              itemText: (item) =>
                "Документ №" +
                (item?.Num ? item?.Num : "") +
                " - " +
                (item?.Name ? item?.Name : ""),
              returnObject: true,
            };
            break;
          case "Files":
            bind = {
              multiple: true,
              class: "d-none",
            };
            break;
          case "Razdel_deyatelnosti":
            bind = {
              dict: "AuditIndicatorCategories",
              itemText: "Name",
              itemValue: "Id",
              returnObject: true,
              clearable: field.Enabled,
              required: true,
            };
            break;
          case "Naimenovanie":
            bind = {
              maxlength: "200",
              counter: true,
            };
            break;
          case "Nomer":
            bind = {
              maxlength: "50",
              counter: "50",
            };
            break;
          case "Data":
            bind = {
              required: true,
            };
            break;
          case "Primechanie":
            bind = {
              maxlength: 200,
              counter: true,
              autoGrow: true,
              rows: "1",
              clearable: true,
            };
        }
        return bind;
      } else if (this.type === 1) {
        switch (field.Name) {
          case "CameFrom":
            bind = {
              items: [
                { id: 1, value: "Из дома" },
                { id: 2, value: "Другое отделение" },
                { id: 3, value: "Другая МО" },
              ],
              "item-value": "id",
            };
            break;
          case "BedsoreStage":
            bind = {
              items: [
                { id: 1, value: "I" },
                { id: 2, value: "II" },
                { id: 3, value: "III" },
                { id: 4, value: "IV" },
              ],
              "item-value": "id",
            };
            break;
          case "AttachedOrganizationEnum":
            bind = {
              items: [
                { id: 0, value: "Не известно" },
                { id: 1, value: "Организация" },
                { id: 2, value: "Другое" },
              ],
              "item-value": "id",
            };
            break;
        }
        if (bind) return bind;
      }

      bind = this.DocumentTemplateParameterType[field.Type].bind;
      return bind;
    },
  },
};
</script>
<style lang="scss">
.dynamic-inputs__item > div {
  width: 100%;
}
</style>
